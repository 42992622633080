/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // if (!Modernizr.svg) {
                //     $('img[src*="svg"]').attr('src', function () {
                //         return $(this).attr('src').replace('.svg', '.png');
                //     });
                // }

                $('body').on('click', '.accordion .accordion__action', function (e) {
                    e.preventDefault();
                    var $elm = $(this).parents('.accordion');

                    if ($elm.hasClass('accordion--active')) {
                        $elm.find('.accordion__body').slideUp(400, "swing", function () {
                            $(this).parent('.accordion').removeClass('accordion--active');
                        });
                    } else {
                        $elm.find('.accordion__body').slideDown(400, "swing", function () {
                            $(this).parent('.accordion').addClass('accordion--active');

                            if ($(this).find('.map--interactive').length && window.google) {
                                var map_container = $(this).find('.map--interactive');
                                google.maps.event.trigger(map_container[0], 'resize');
                                // center map
                                if (map_container.data('map')) {
                                    center_map(map_container.data('map'));
                                }

                            }
                        });
                    }
                });

                var pull = $('.page-navigation__menu'),
                    menu = $('.page-navigation__list'),
                    menuHeight = menu.height();

                $(pull).on('click', function (e) {
                    e.preventDefault();
                    menu.slideToggle(300, "swing");
                });

                $('#scrollToTop').on('click', function (e) {
                    $('html, body').animate({
                        scrollTop: 0
                    });
                });

                var eventName = $('html').hasClass('no-touch') ? 'scroll' : 'touchmove';

                var lastKnownScrollY = 0,
                    ticking = false;

                $(window).on(eventName, function (e) {
                    lastKnownScrollY = $(this).scrollTop();
                    requestTick();
                });

                var requestTick = function () {
                    if (!ticking) {
                        requestAnimationFrame(scrollTop);
                        ticking = true;
                    }
                };

                var scrollTop = function () {
                    if (lastKnownScrollY > 400) {
                        $('#scrollToTop').addClass('scrollToTop--visible');
                    } else {
                        $('#scrollToTop').removeClass('scrollToTop--visible');
                    }
                    ticking = false;
                };

            },
            finalize: function () {

                if (window.location.hash !== "" && window.location.hash !== "#") {
                    var $target = $(window.location.hash);
                    $target = $target.length ? $target : $('[name=' + window.location.hash.slice(1) + ']');
                    if ($target.length) {
                        $('html,body').animate({
                            scrollTop: $target.offset().top - 32
                        }, 1000);

                        if ($target.parent('.accordion').length > 0) {
                            $target.parent('.accordion').slideDown("slow", "swing", function () {
                                $(this).addClass('accordion--active');
                                $('.page-subnavigation__list').find('a[href="' + window.location.hash + '"]').parent().addClass('active');
                            });
                        }
                    }
                }

                // JavaScript to be fired on all pages, after page specific JS is fired
                $('.share-list__print').on('click', function () {
                    var $this = $(this),
                        $item = $this.parents('.content-list__item');
                    var $siblings = $item.siblings().not($item).addClass('hide-for-print');

                    window.print();
                    $siblings.removeClass('hide-for-print');
                });

                $('.share-list__mail').on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);

                    var heading = $this.parents('.content-list__item').find('h2').text() || '';
                    var subject = 'Empfehlung: ' + heading;

                    var mailtoStr = '?subject=' + subject + '&body=' + encodeURI(document.location.href);
                    window.location.href = 'mailto:' + mailtoStr;
                });

                if ($('.page-subnavigation__list').length) {

                    $(".page-subnavigation__list").sticky({ topSpacing: 24 });
                }

                var queue = $({});//$('body');
                var animateOn = $('html').hasClass('lt-ie9') ? 'html, body' : 'html, body';

                $('.page-subnavigation__list').on('click', 'a[href*="#"]:not([href="#"])', function (e) {
                    e.preventDefault();

                    $(this).parents('ul').find('.active').removeClass('active');

                    $('.accordion--active').each(function () {
                        var this_ = $(this);
                        queue.queue("acc", function (next) {
                            this_.find('.accordion__body').slideUp(400, "swing", function () {
                                $(this).parent('.accordion').toggleClass('accordion--active');
                                next();
                            });
                        });
                    });

                    var hash = $(this).attr('href'),
                        $target = $(hash).parent('.accordion');
                    this_ = $(this);

                    if (!$(hash).parent('.accordion').hasClass('accordion--active')) {

                        queue.queue("acc", function (next) {

                            var scrollOffset = $target.offset().top;
                            $(animateOn).animate({
                                scrollTop: $target.offset().top - 20
                            }, "slow", function () {
                                if ($(hash).length && $(hash).parent().hasClass('accordion')) {
                                    $(hash).parent().find('.accordion__action').trigger('click');

                                    if (history.pushState) {
                                        history.pushState(null, null, hash);
                                    }
                                    this_.parent().addClass('active');
                                }
                            });
                            next();
                        });
                    } else {
                        $(hash).find('.accordion__action').trigger('click');
                    }

                    queue.dequeue("acc");

                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'kfz_schnellrechner': {
            init: function () {

            },
            finalize: function () {
                var replaceHTML = '<iframe style="width: 100%; height: 1070px;" src="https://www.insurancestation.de/makler/default.asp?domain=dkb_kfz_rechner" width="1000" height="1220" scrolling="true"></iframe>';

                $('#nafi_optin_checkbox').on('change', function (e) {
                    if ($(this).prop('checked')) {
                        $('#nafi_optin').replaceWith(replaceHTML);
                    }
                });
            }
        },
        'fidessecur_aktuell_online': {
            init: function () {

            },
            finalize: function () {
                var $iframe = $('iframe');
                if ($iframe.length) {
                    $iframe.iFrameResize();
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
